import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _baebac20 = () => interopDefault(import('..\\pages\\drug-selection-page.vue' /* webpackChunkName: "pages_drug-selection-page" */))
const _3b5b15bb = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _662e3c88 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _6a91d41c = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _6a9feb9d = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _6abc1a9f = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _6aca3220 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _c10fe146 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _26845d77 = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _17b7d6ce = () => interopDefault(import('..\\pages\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_need-a-card_patient-information" */))
const _704dc7ed = () => interopDefault(import('..\\pages\\need-a-card\\success.vue' /* webpackChunkName: "pages_need-a-card_success" */))
const _10d610f6 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/drug-selection-page",
    component: _baebac20,
    name: "drug-selection-page"
  }, {
    path: "/error",
    component: _3b5b15bb,
    name: "error"
  }, {
    path: "/rejection",
    component: _662e3c88,
    name: "rejection"
  }, {
    path: "/errors/400",
    component: _6a91d41c,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _6a9feb9d,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _6abc1a9f,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _6aca3220,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _c10fe146,
    name: "errors-500"
  }, {
    path: "/need-a-card/eligibility",
    component: _26845d77,
    name: "need-a-card-eligibility"
  }, {
    path: "/need-a-card/patient-information",
    component: _17b7d6ce,
    name: "need-a-card-patient-information"
  }, {
    path: "/need-a-card/success",
    component: _704dc7ed,
    name: "need-a-card-success"
  }, {
    path: "/",
    component: _10d610f6,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
